import * as React from 'react';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import {
   container,
   containerTop,
   heading,
   navLinks,
   navLinkItem,
   navLinkText,
   cardCarouselContainer,
   clickableCardsHolder,
   clickableCard,
   bottomCard,
   shortDescription,
   wouldItWork,
   roundedImage,
   fullDiv,
   justCard,
   carousel1Winner,
   sectionBackground,
   card1Winner,
   card1textWinner,
   sectionTitle,
   ourImageBorderRadiusTop,
} from './cardCarousel.module.css';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled, { keyframes } from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useSpring, animated, Transition } from 'react-spring';
import { useInView, InView } from 'react-intersection-observer';

const Container = styled(animated.div)`
   background: ${(props) => props.primary};
   color: ${(props) => props.textColor};

}};
`;

const aAnimation = keyframes`
0% {
      background-position: 0% 50%;
   }
   50% {
      background-position: 100% 50%;
   }
   100% {
      background-position: 0% 50%;
   }
}`;

const CoolContainer = styled(animated.div)`
   color: ${(props) => props.textColor};
   background: linear-gradient(39deg, #f9e64f, #a9cef4, #f9e64f);
   background-size: 180% 180%;
   animation-name: ${aAnimation};
   animation-duration: 8s;
   animation-iteration-count: infinite;
}};
`;

const bAnimation = keyframes`
 0% { height: 100px; width: 100px; }
 30% { height: 400px; width: 400px; opacity: 1 }
 40% { height: 405px; width: 405px; opacity: 0.3; }
 100% { height: 100px; width: 100px; opacity: 0.6; }
}`;

const Circle = styled.div`
   height: 100px;
   width: 100px;
   border-style: solid;
   border-width: 5px;
   border-radius: 50%;
   border-color: black;
   animation-name: ${bAnimation};
   animation-duration: 8s;
   animation-iteration-count: infinite;
`;

const StyledCard = styled(animated.div)`
   border-radius: 15px 15px 15px 15px !important;
   box-shadow: ${(props) => props.theBoxShadow}};
   margin-bottom: 45px;

`;

const propBoxShadow =
   '0 20px 60px rgba(120, 120, 120, 0.4), 0 -10px 30px rgba(120, 120, 120, 0.15)';

const CardCarousel = ({
   theBoxShadow,
   sectionTitleText,
   backgroundColor,
   textColor,
   sourceInstanceNameProp,
   cardDataArray,
   style,
   isLeft,
   coolContainer,
}) => {
   // const [ref] = useInView();
   // const [ref, isInView] = useInView();

   // const [greetingStatus, displayGreeting] = React.useState(false);

   // const { ref, inView, entry } = useInView({
   //    /* Optional options */
   //    // threshold: 0.1,
   //    // threshold: 0,
   //    // threshold: 1,
   //    // threshold: 0,
   //    // threshold: 0,
   //    threshold: 0,
   // });

   const data = useStaticQuery(graphql`
      query {
         allFile(
            sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
            filter: { extension: { eq: "mdx" } }
         ) {
            nodes {
               childMdx {
                  frontmatter {
                     date(formatString: "MMMM D, YYYY")
                     title
                  }
                  id
                  slug
               }
               sourceInstanceName
            }
         }
      }
   `);

   console.log('cardCarousel', data);
   console.log('cardDataArray', cardDataArray);

   let click = () => {
      navigate('/blog');
   };

   const [greetingStatus, setGreetingStatus] = React.useState(false);
   // const contentProps = useSpring({
   //    opacity: greetingStatus ? 1 : 0,
   //    marginTop: greetingStatus ? 0 : -500,
   // });

   // const contentProps = useSpring({
   //    // opacity: 1,
   //    // marginTop: 0,
   //    from: inView ? { opacity: 0, x: -500 } : { opacity: 0, x: -500 },
   //    to: inView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 },
   //    delay: inView ? 500 : 500,
   //    config: inView ? { duration: 1500 } : { duration: 1500 },
   // });

   const [testStatus, setTestStatus] = React.useState(false);

   const moveRight = useSpring({
      opacity: testStatus ? 1 : 0,
      marginTop: testStatus ? 0 : -500,
   });

   const [aInView, setAInView] = React.useState(false);

   const { ref, inView, entry } = useInView({
      /* Optional options */
      // threshold: 0.5,
      // threshold: 0.25,
      threshold: 0.1,
      triggerOnce: true,
   });

   const coolTestStyle = useSpring({
      // loop: true,
      from: inView ? { opacity: 0, x: -500 } : { opacity: 0, x: -500 },
      to: inView ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 },
      delay: inView ? 500 : 500,
      config: inView ? { duration: 1500 } : { duration: 1500 },
   });

   const superCoolStyle = useSpring({
      // loop: true,
      from: { opacity: 0, x: -500 },
      to: { opacity: 1, x: 0 },
      delay: 500,
      config: { duration: 1500 },
   });

   const [myTestBool, setMyTestBool] = React.useState(false);

   // const contentProps = useSpring({
   //    opacity: myTestBool ? 1 : 0.25,
   //    marginLeft: myTestBool ? 0 : -100,
   //    delay: 500,
   //    config: { duration: 750 },
   // });

   const contentProps = useSpring({
      opacity: myTestBool ? 1 : 0,
      marginLeft: myTestBool ? 0 : isLeft ? -150 : 150,
      delay: 250,
      config: { duration: 750 },
   });
   return (
      <div>
         {coolContainer ? (
            <CoolContainer primary={backgroundColor} textColor={textColor}>
               {/* <br />
               <br />
               <br /> */}
               {/* WOW WOW - let's remove ++
               {myTestBool && (
                  <animated.div style={contentProps}>
                     <div className="container">
                        <div className="row">
                           {cardDataArray.map((x) => (
                              <animated.div
                                 ref={ref}
                                 className="col-md d-flex justify-content-center"
                                 style={style}
                              >
                                 <div
                                    onClick={() =>
                                       navigate(
                                          `${x.sourceInstanceName}/${x.childMdx.slug}`
                                       )
                                    }
                                 >
                                    <StyledCard theBoxShadow={theBoxShadow}>
                                       <div className={card1Winner}>
                                          <GatsbyImage
                                             image={getImage(
                                                x.childMdx.frontmatter
                                                   .hero_image
                                             )}
                                             alt={
                                                x.childMdx.frontmatter
                                                   .hero_image_alt
                                             }
                                             className={ourImageBorderRadiusTop}
                                          />
                                          <div className={card1textWinner}>
                                             <h3>
                                                {x.childMdx.frontmatter.title
                                                   .length < 17
                                                   ? x.childMdx.frontmatter
                                                        .title
                                                   : x.childMdx.frontmatter.title.slice(
                                                        0,
                                                        17
                                                     ) + '...'}
                                             </h3>
                                             {x.childMdx.excerpt}
                                          </div>
                                       </div>
                                    </StyledCard>
                                 </div>
                              </animated.div>
                           ))}
                        </div>
                     </div>
                  </animated.div>
               )}
               WOW - let's remove -- */}
               {/* <div className="button-container">
                  <button
                     onClick={() => setMyTestBool(true)}
                     className="button"
                  >
                     Click Here
                  </button>
               </div>{' '} */}

               <br />
               <br />
               <br />
               <p>{sourceInstanceNameProp}</p>
               <br />
               <div className={sectionTitle}>{sectionTitleText}</div>
               <InView
                  as="div"
                  onChange={(inView, entry) => {
                     console.log('Inview: HIIII???', inView);
                     console.log('Inview: HIIII???', inView);
                     // setMyTestBool(true);
                     // setMyTestBool(inView);
                     // setMyTestBool(true);
                     setMyTestBool(inView);
                     function noRefCheck() {}
                  }}
                  // onChange={function noRefCheck() {}}
                  triggerOnce
               >
                  {/* HIIIIIIIIIIIIIIIII */}
               </InView>
               <div className="container">
                  {/* <animated.div className="row" style={style}> */}
                  <div className="row">
                     {cardDataArray.map((x) => (
                        <animated.div
                           ref={ref}
                           className="col-md d-flex justify-content-center"
                           // style={style}
                           style={contentProps}
                        >
                           <div
                              onClick={() =>
                                 navigate(
                                    `${x.sourceInstanceName}/${x.childMdx.slug}`
                                 )
                              }
                           >
                              <StyledCard theBoxShadow={theBoxShadow}>
                                 <div className={card1Winner}>
                                    <GatsbyImage
                                       image={getImage(
                                          x.childMdx.frontmatter.hero_image
                                       )}
                                       alt={
                                          x.childMdx.frontmatter.hero_image_alt
                                       }
                                       className={ourImageBorderRadiusTop}
                                    />
                                    <div className={card1textWinner}>
                                       <h3>
                                          {x.childMdx.frontmatter.title.length <
                                          17
                                             ? x.childMdx.frontmatter.title
                                             : x.childMdx.frontmatter.title.slice(
                                                  0,
                                                  17
                                               ) + '...'}
                                       </h3>
                                       {x.childMdx.excerpt}
                                    </div>
                                 </div>
                              </StyledCard>
                           </div>
                        </animated.div>
                     ))}
                  </div>
                  {/* </animated.div> */}
               </div>
               <br />
            </CoolContainer>
         ) : (
            <Container primary={backgroundColor} textColor={textColor}>
               {/* <br />
               <br />
               <br /> */}
               {/* WOW WOW - let's remove ++
               {myTestBool && (
                  <animated.div style={contentProps}>
                     <div className="container">
                        <div className="row">
                           {cardDataArray.map((x) => (
                              <animated.div
                                 ref={ref}
                                 className="col-md d-flex justify-content-center"
                                 style={style}
                              >
                                 <div
                                    onClick={() =>
                                       navigate(
                                          `${x.sourceInstanceName}/${x.childMdx.slug}`
                                       )
                                    }
                                 >
                                    <StyledCard theBoxShadow={theBoxShadow}>
                                       <div className={card1Winner}>
                                          <GatsbyImage
                                             image={getImage(
                                                x.childMdx.frontmatter
                                                   .hero_image
                                             )}
                                             alt={
                                                x.childMdx.frontmatter
                                                   .hero_image_alt
                                             }
                                             className={ourImageBorderRadiusTop}
                                          />
                                          <div className={card1textWinner}>
                                             <h3>
                                                {x.childMdx.frontmatter.title
                                                   .length < 17
                                                   ? x.childMdx.frontmatter
                                                        .title
                                                   : x.childMdx.frontmatter.title.slice(
                                                        0,
                                                        17
                                                     ) + '...'}
                                             </h3>
                                             {x.childMdx.excerpt}
                                          </div>
                                       </div>
                                    </StyledCard>
                                 </div>
                              </animated.div>
                           ))}
                        </div>
                     </div>
                  </animated.div>
               )}
               WOW - let's remove -- */}
               {/* <div className="button-container">
                  <button
                     onClick={() => setMyTestBool(true)}
                     className="button"
                  >
                     Click Here
                  </button>
               </div>{' '} */}

               <br />
               <br />
               <br />
               <p>{sourceInstanceNameProp}</p>
               <br />
               <div className={sectionTitle}>{sectionTitleText}</div>
               <InView
                  as="div"
                  onChange={(inView, entry) => {
                     console.log('Inview: HIIII???', inView);
                     console.log('Inview: HIIII???', inView);
                     // setMyTestBool(true);
                     // setMyTestBool(inView);
                     // setMyTestBool(true);
                     setMyTestBool(inView);
                     function noRefCheck() {}
                  }}
                  // onChange={function noRefCheck() {}}
                  triggerOnce
               >
                  {/* HIIIIIIIIIIIIIIIII */}
               </InView>
               <div className="container">
                  {/* <animated.div className="row" style={style}> */}
                  <div className="row">
                     {cardDataArray.map((x) => (
                        <animated.div
                           ref={ref}
                           className="col-md d-flex justify-content-center"
                           // style={style}
                           style={contentProps}
                        >
                           <div
                              onClick={() =>
                                 navigate(
                                    `${x.sourceInstanceName}/${x.childMdx.slug}`
                                 )
                              }
                           >
                              <StyledCard theBoxShadow={theBoxShadow}>
                                 <div className={card1Winner}>
                                    <GatsbyImage
                                       image={getImage(
                                          x.childMdx.frontmatter.hero_image
                                       )}
                                       alt={
                                          x.childMdx.frontmatter.hero_image_alt
                                       }
                                       className={ourImageBorderRadiusTop}
                                    />
                                    <div className={card1textWinner}>
                                       <h3>
                                          {x.childMdx.frontmatter.title.length <
                                          17
                                             ? x.childMdx.frontmatter.title
                                             : x.childMdx.frontmatter.title.slice(
                                                  0,
                                                  17
                                               ) + '...'}
                                       </h3>
                                       {x.childMdx.excerpt}
                                    </div>
                                 </div>
                              </StyledCard>
                           </div>
                        </animated.div>
                     ))}
                  </div>
                  {/* </animated.div> */}
               </div>
               <br />
            </Container>
         )}
      </div>
   );
};

export default CardCarousel;

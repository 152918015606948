import * as React from 'react';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import {
   container,
   containerTop,
   heading,
   navLinks,
   navLinkItem,
   navLinkText,
   cardCarouselContainer,
   clickableCardsHolder,
   clickableCard,
   bottomCard,
   shortDescription,
   wouldItWork,
   roundedImage,
   fullDiv,
   justCard,
   carousel1Winner,
   sectionBackground,
   card1Winner,
   card1textWinner,
   sectionTitle,
   ourImageBorderRadiusTop,
   wrapperContainer,
   wrapperContainer2,
   flexContainerFlyingLogos,
} from './floatingLogos.module.css';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled, { keyframes } from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
   useSpring,
   animated,
   Transition,
   useTransition,
   config,
} from 'react-spring';

// const Container = styled.div`
//    background: ${(props) => props.primary};
//    color: ${(props) => props.textColor};

// }};
// `;

const Container = styled(animated.div)`
   background: ${(props) => props.primary};
   color: ${(props) => props.textColor};

}};
`;

// const StyledCard = styled.div`
//    border-radius: 15px 15px 15px 15px !important;
//    box-shadow: ${(props) => props.theBoxShadow}};
//    margin-bottom: 45px;
// `;

const aAnimation = keyframes`
0% {
      background-position: 0% 50%;
   }
   50% {
      background-position: 100% 50%;
   }
   100% {
      background-position: 0% 50%;
   }
}`;

const CoolContainer = styled(animated.div)`
   color: ${(props) => props.textColor};
   background: linear-gradient(39deg, #f9e64f, #a9cef4, #f9e64f);
   background-size: 180% 180%;
   animation-name: ${aAnimation};
   animation-duration: 8s;
   animation-iteration-count: infinite;
}};
`;

const bAnimation = keyframes`
 0% { height: 100px; width: 100px; }
 30% { height: 400px; width: 400px; opacity: 1 }
 40% { height: 405px; width: 405px; opacity: 0.3; }
 100% { height: 100px; width: 100px; opacity: 0.6; }
}`;

const Circle = styled.div`
   height: 100px;
   width: 100px;
   border-style: solid;
   border-width: 5px;
   border-radius: 50%;
   border-color: black;
   animation-name: ${bAnimation};
   animation-duration: 8s;
   animation-iteration-count: infinite;
`;

const StyledCard = styled(animated.div)`
   border-radius: 15px 15px 15px 15px !important;
   box-shadow: ${(props) => props.theBoxShadow}};
   margin-bottom: 45px;

`;

const propBoxShadow =
   '0 20px 60px rgba(120, 120, 120, 0.4), 0 -10px 30px rgba(120, 120, 120, 0.15)';

const FloatingLogos = ({
   theBoxShadow,
   sectionTitleText,
   backgroundColor,
   textColor,
   sourceInstanceNameProp,
   cardDataArray,
   style,
   coolContainer,
}) => {
   const data = useStaticQuery(graphql`
      query {
         allFile(
            sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
            filter: {
               extension: { eq: "mdx" }
               sourceInstanceName: { eq: "floatingLogos" }
            }
         ) {
            nodes {
               childMdx {
                  frontmatter {
                     date(formatString: "MMMM D, YYYY")
                     title
                     hero_image_alt
                     hero_image {
                        childImageSharp {
                           gatsbyImageData(
                              layout: CONSTRAINED
                              width: 350
                              height: 300
                              placeholder: NONE
                              transformOptions: {
                                 grayscale: true
                                 fit: CONTAIN
                                 cropFocus: ATTENTION
                              }
                           )
                        }
                     }
                  }
                  id
                  slug
               }
               sourceInstanceName
            }
         }
      }
   `);

   console.log('floatingLogosArray', data);
   console.log('cardDataArray', cardDataArray);

   let click = () => {
      navigate('/blog');
   };

   // const transitions = useTransition(cardDataArray, {
   //    from: { opacity: 0 },
   //    enter: { opacity: 1 },
   //    leave: { opacity: 0 },
   //    // delay: 200,
   //    config: config.molasses,
   //    onRest: () => setItems([]),
   // });

   const [floatingLogosNodesArray, setFloatingLogosNodesArray] = React.useState(
      []
   );

   React.useEffect(() => {
      setFloatingLogosNodesArray(data.allFile.nodes);
   }, [data]);

   return (
      <>
         <div>
            <Container primary={backgroundColor} textColor={textColor}>
               <br />
               <br />
               <div className={sectionTitle}>{sectionTitleText}</div>
               <div className="container">
                  <div className={flexContainerFlyingLogos}></div>
                  <div className={wrapperContainer2}>
                     {/* LEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEET */}
                     &nbsp;
                  </div>
                  <div className={wrapperContainer}>
                     {/* RIGHTTTTTTTT RIGHTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT */}
                     &nbsp;
                  </div>
                  <animated.div className="row" style={style}>
                     {/* {cardDataArray.map((x) => ( */}
                     {/* {data.nodes.map((x) => ( */}
                     {floatingLogosNodesArray &&
                        floatingLogosNodesArray.map((x) => (
                           <div className="col-md d-flex justify-content-center">
                              <div
                                 onClick={() =>
                                    navigate(
                                       `${x.sourceInstanceName}/${x.childMdx.slug}`
                                    )
                                 }
                              >
                                 {/* <StyledCard theBoxShadow={theBoxShadow}>
                                    <div className={card1Winner}> */}
                                 <GatsbyImage
                                    image={getImage(
                                       x.childMdx.frontmatter.hero_image
                                    )}
                                    alt={x.childMdx.frontmatter.hero_image_alt}
                                    className={ourImageBorderRadiusTop}
                                 />
                                 {/* </div>
                                 </StyledCard> */}
                                 {/* <StyledCard theBoxShadow={theBoxShadow}>
                                    <div className={card1Winner}>
                                       <GatsbyImage
                                          image={getImage(
                                             x.childMdx.frontmatter.hero_image
                                          )}
                                          alt={
                                             x.childMdx.frontmatter
                                                .hero_image_alt
                                          }
                                          className={ourImageBorderRadiusTop}
                                       />
                                       <div className={card1textWinner}>
                                          <h3>
                                             {x.childMdx.frontmatter.title
                                                .length < 17
                                                ? x.childMdx.frontmatter.title
                                                : x.childMdx.frontmatter.title.slice(
                                                     0,
                                                     17
                                                  ) + '...'}
                                          </h3>
                                          {x.childMdx.excerpt}
                                       </div>
                                    </div>
                                 </StyledCard> */}
                              </div>
                           </div>
                        ))}
                  </animated.div>
               </div>
            </Container>
            )
         </div>
      </>
   );
};

export default FloatingLogos;

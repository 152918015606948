import * as React from 'react';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/index.css';
import CardCarousel from '../components/cardCarousel';
import FloatingLogos from '../components/floatingLogos';
// import TwitterSection from '../components/twitterSection';
import { Link, useStaticQuery, graphql } from 'gatsby';
// import { useSpring, animated } from 'react-spring';
import { useSpring, animated, Transition } from 'react-spring';
import styled from 'styled-components';

// const SuperFunctional = ({ cool }) => {
//    return (
//       <>
//          <div>This is the super functional component</div>
//          <div>This is the super functional component</div>
//          <div>This is the super functional component</div>
//       </>
//    );
// };

const SuperFunctional = (props) => {
   return (
      <>
         <animated.div style={props.style}>
            <div>This is the super functional component</div>
            <div>This is the super functional component</div>
            <div>This is the super functional component</div>
         </animated.div>
      </>
   );
};

const IndexPage = ({ data }) => {
   console.log('index.js-full-data', data);
   const [arrayOne, setArrayOne] = React.useState([]);
   const [arrayTwo, setArrayTwo] = React.useState([]);
   const [arrayThree, setArrayThree] = React.useState([]);

   const mysticalUnionString = 'mysticalUnion';
   const livingFromAboveString = 'livingFromAbove';
   const overcomingString = 'overcoming';

   React.useEffect(() => {
      if (data) {
         setArrayOne(
            data.allFile.nodes
               // .filter((x) => x.sourceInstanceName == '')
               .filter((x) => x.sourceInstanceName == mysticalUnionString)
               .slice(0, 3)
         );
         console.log('setArrayOne', arrayOne);
         setArrayTwo(
            data.allFile.nodes
               .filter((x) => x.sourceInstanceName == livingFromAboveString)
               .slice(0, 3)
         );
         console.log('setArrayTwo', arrayTwo);
         setArrayThree(
            data.allFile.nodes
               .filter((x) => x.sourceInstanceName == overcomingString)
               .slice(0, 3)
         );
         console.log('setArrayThree', arrayThree);
      }
   }, [data]);

   console.log('process.env.GATSBY_WHAT_FILE', process.env.GATSBY_WHAT_FILE);
   console.log('process.env.NODE_ENV', process.env.NODE_ENV);

   const springStyleOne = useSpring({
      from: { opacity: 0, y: 30 },
      to: { opacity: 1, y: 0 },
      reset: false,
      delay: 300,
   });

   const springStyleTwo = useSpring({
      from: { opacity: 0, y: 20 },
      to: { opacity: 1, y: 0 },
      delay: 400,
      config: { duration: 500 },
   });

   const springStyleThree = useSpring({
      from: { opacity: 0, y: 20 },
      to: { opacity: 1, y: 0 },
      delay: 400,
      config: { duration: 1500 },
   });

   //LEFT TO RIGHT
   const springStyleFour = useSpring({
      from: { opacity: 0, x: -30 },
      to: { opacity: 1, x: 0 },
      delay: 400,
      config: { duration: 1500 },
   });

   //RIGHT TO LEFT
   const springStyleFive = useSpring({
      from: { opacity: 0, x: 30 },
      to: { opacity: 1, x: 0 },
      delay: 400,
      config: { duration: 1500 },
   });

   //RIGHT TO LEFT
   const springStyleSix = useSpring({
      from: { opacity: 0, x: 1000 },
      to: { opacity: 1, x: -1000 },
      loop: true,
      config: { duration: 4500 },
   });
   // delay: 400,

   // const springStyleSeven = useSpring({
   //    from: { opacity: 0, x: 1000 },
   //    to: [
   //       { opacity: 1, x: 900 },
   //       { opacity: 1, x: -900 },
   //       { opacity: 0, x: -1000 },
   //    ],
   //    loop: true,
   //    // config: { duration: 4500 },
   // });

   const springStyleSeven = useSpring({
      from: { opacity: 1, x: 1500 },
      to: [
         // { opacity: 1, x: 900 },
         // { opacity: 1, x: -900 },
         { opacity: 1, x: -1500 },
      ],
      loop: true,
      config: { duration: 7500 },
   });

   const n = React.useRef(0);
   const styles = useSpring({
      // loop: true,
      loop: () => 3 > n.current++,
      from: { x: 0, rotateZ: 0 },
      to: { x: 100, rotateZ: 180 },
      delay: 300,
   });

   const AnimatedCardCarousel = animated(CardCarousel);

   // const { x, y } = useSpring({ x: 0, y: -100 });

   const AnimatedSuperFunctional = animated(SuperFunctional);

   // const StyledDiv = styled.div`
   //    border-radius: 15px 15px 15px 15px !important;
   //    color: red;
   // `;

   // const StyledDiv = styled(div`
   //    border-radius: 15px 15px 15px 15px !important;
   //    color: red;
   // `);

   const StyledDiv = styled(animated.div)`
      border-radius: 15px 15px 15px 15px !important;
      color: red;
   `;

   return (
      <>
         <Layout>
            <div className="containerBottom">
               <div className="theContainer">
                  <animated.h1 style={springStyleOne} className="theTitle">
                     Christian Ecstaticism
                  </animated.h1>
                  <animated.div style={springStyleTwo} className="theSubtitle">
                     Living in the love of God
                  </animated.div>
                  <br />

                  {/* <div className="react-spring-functional-test theSubtitle">
                     NO:
                     <AnimatedSuperFunctional style={{ springStyleTwo }} />
                     YES:
                     <AnimatedSuperFunctional style={springStyleOne} />
                     YES:
                     <AnimatedSuperFunctional
                        style={springStyleOne}
                     ></AnimatedSuperFunctional>
                     YES:
                     <animated.div style={springStyleOne}>
                        This is not the super functional component.
                     </animated.div>
                     NO:
                     <StyledDiv>This is the Styled Div with no style</StyledDiv>
                     YES:
                     <StyledDiv style={springStyleOne}>
                        This is the Styled Div with style
                     </StyledDiv>
                  </div> */}
               </div>
               <div className="centerImage">
                  <StaticImage
                     alt="StJoseph"
                     src="../images/StJoseph.jpg"
                     width="750"
                  />
               </div>
               <div className="imagesHolder">
                  <div>
                     <StaticImage
                        alt="FirstImage"
                        src="../images/StJoseph.jpg"
                        className="roundedImage"
                        width="250"
                     />
                  </div>
                  <div>
                     <StaticImage
                        alt="FirstImage"
                        src="../images/StJoseph.jpg"
                        className="roundedImage"
                        width="250"
                     />
                  </div>
                  <div>
                     <StaticImage
                        alt="FirstImage"
                        src="../images/StJoseph.jpg"
                        className="roundedImage"
                        width="250"
                     />
                  </div>
               </div>{' '}
               <div className="twitterContainerColor">
                  <div className="container">
                     <div className="twitterContainerSpacer">
                        <div className="row">
                           <div className="col-md d-flex justify-content-center">
                              <div className="twitterBottomMargin">
                                 <StaticImage
                                    alt="FirstImage"
                                    src="../images/jpaImage1.png"
                                    className="twitterImage"
                                    width="250"
                                 />
                              </div>
                           </div>
                           <div className="col-md d-flex justify-content-center">
                              <div className="twitterBottomMargin">
                                 <StaticImage
                                    alt="FirstImage"
                                    src="../images/jpaImage3.png"
                                    className="twitterImage"
                                    width="250"
                                 />
                              </div>
                           </div>
                           <div className="col-md d-flex justify-content-center">
                              <div className="twitterBottomMargin">
                                 <StaticImage
                                    alt="FirstImage"
                                    src="../images/jpaImage2.png"
                                    className="twitterImage"
                                    width="250"
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="floatingLogoHolder">
               <FloatingLogos
                  sectionTitleText="Our Brands"
                  backgroundColor="#fdf7ec"
                  textColor="black"
                  theBoxShadow="0 20px 60px rgba(76, 64, 46, 0.4),0 -10px 30px rgba(76, 64, 46, 0.5)"
                  sourceInstanceNameProp="Level 1"
                  cardDataArray={arrayOne}
                  // style={springStyleThree}
                  style={springStyleSeven}
                  coolContainer={false}
               ></FloatingLogos>
            </div>
            <AnimatedCardCarousel
               sectionTitleText="Mystical Union"
               backgroundColor="#fdf7ec"
               textColor="black"
               theBoxShadow="0 20px 60px rgba(76, 64, 46, 0.4),0 -10px 30px rgba(76, 64, 46, 0.5)"
               sourceInstanceNameProp="Level 1"
               cardDataArray={arrayOne}
               // style={springStyleThree}
               style={springStyleFour}
               isLeft={true}
               coolContainer={true}
            ></AnimatedCardCarousel>
            <CardCarousel
               sectionTitleText="Mystical Union"
               backgroundColor="#fdf7ec"
               textColor="black"
               theBoxShadow="0 20px 60px rgba(76, 64, 46, 0.4),0 -10px 30px rgba(76, 64, 46, 0.5)"
               sourceInstanceNameProp="Level 1"
               cardDataArray={arrayOne}
               style={springStyleFour}
               isLeft={false}
               coolContainer={false}
            ></CardCarousel>
            <CardCarousel
               sectionTitleText="Living From Above"
               backgroundColor="#dd0531"
               textColor="yellow"
               theBoxShadow="0 20px 60px rgba(76, 64, 46, 0.4),0 -10px 30px rgba(76, 64, 46, 0.5)"
               sourceInstanceNameProp="Level 2"
               cardDataArray={arrayTwo}
               style={springStyleFive}
               isLeft={true}
               coolContainer={false}
            ></CardCarousel>
            <CardCarousel
               sectionTitleText="Overcoming"
               backgroundColor="#212429"
               // textColor="#212429"
               textColor="#fdf7ec"
               theBoxShadow="0 20px 60px rgba(120, 120, 120, 0.4), 0 -10px 30px rgba(120, 120, 120, 0.15)"
               sourceInstanceNameProp="Level 3"
               cardDataArray={arrayThree}
               style={springStyleFour}
               isLeft={false}
               coolContainer={false}
            ></CardCarousel>
         </Layout>
      </>
   );
};

export const query = graphql`
   query {
      allFile(
         sort: { fields: [childrenMdx___frontmatter___date], order: ASC }
         filter: { extension: { eq: "mdx" } }
      ) {
         nodes {
            childMdx {
               frontmatter {
                  date(formatString: "MMMM D, YYYY")
                  title
                  category
                  hero_image_alt
                  hero_image {
                     childImageSharp {
                        gatsbyImageData(
                           layout: CONSTRAINED
                           width: 350
                           height: 300
                           transformOptions: {
                              fit: CONTAIN
                              cropFocus: ATTENTION
                           }
                        )
                     }
                  }
               }
               id
               slug
               body
               excerpt(pruneLength: 100)
            }
            sourceInstanceName
         }
      }
   }
`;

export default IndexPage;

// extracted by mini-css-extract-plugin
export var beige = "cardCarousel-module--beige--1ZR8D";
export var sectionBackground = "cardCarousel-module--sectionBackground--2G6x-";
export var sectionTitle = "cardCarousel-module--sectionTitle--3B3NZ";
export var carousel1Winner = "cardCarousel-module--carousel1Winner--371yA";
export var card1Winner = "cardCarousel-module--card1Winner--TRgE5";
export var card1textWinner = "cardCarousel-module--card1textWinner--HEG8L";
export var fullDiv = "cardCarousel-module--fullDiv--43CCh";
export var justCard = "cardCarousel-module--justCard--2OFuN";
export var cardCarouselContainer = "cardCarousel-module--cardCarouselContainer--1JQB0";
export var clickableCardsHolder = "cardCarousel-module--clickableCardsHolder--2v2aC";
export var bottomCard = "cardCarousel-module--bottomCard--yY44f";
export var clickableCard = "cardCarousel-module--clickableCard--3z9ZN";
export var shortDescription = "cardCarousel-module--shortDescription--3pL0H";
export var wouldItWork = "cardCarousel-module--wouldItWork--3jdKP";
export var roundedImage = "cardCarousel-module--roundedImage--2qBr4";
export var ourImageBorderRadiusTop = "cardCarousel-module--ourImageBorderRadiusTop--1av0d";
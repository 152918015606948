// extracted by mini-css-extract-plugin
export var beige = "floatingLogos-module--beige--35nqK";
export var sectionBackground = "floatingLogos-module--sectionBackground--3az-K";
export var sectionTitle = "floatingLogos-module--sectionTitle--1R16x";
export var carousel1Winner = "floatingLogos-module--carousel1Winner--k0Adz";
export var card1Winner = "floatingLogos-module--card1Winner--2xSS1";
export var card1textWinner = "floatingLogos-module--card1textWinner--17wn9";
export var fullDiv = "floatingLogos-module--fullDiv--j5s8d";
export var justCard = "floatingLogos-module--justCard--3QLZu";
export var cardCarouselContainer = "floatingLogos-module--cardCarouselContainer--FYZZQ";
export var clickableCardsHolder = "floatingLogos-module--clickableCardsHolder--haDn6";
export var bottomCard = "floatingLogos-module--bottomCard--3xHcm";
export var clickableCard = "floatingLogos-module--clickableCard--TvyZV";
export var shortDescription = "floatingLogos-module--shortDescription--6TFAN";
export var wouldItWork = "floatingLogos-module--wouldItWork--2xkhw";
export var roundedImage = "floatingLogos-module--roundedImage--3IHNb";
export var ourImageBorderRadiusTop = "floatingLogos-module--ourImageBorderRadiusTop--37j-s";
export var wrapperContainer = "floatingLogos-module--wrapperContainer--o75tP";
export var wrapperContainer2 = "floatingLogos-module--wrapperContainer2--_2ZbD";
export var flexContainerFlyingLogos = "floatingLogos-module--flexContainerFlyingLogos--2QrUm";